import { columnNamesType, OctTable } from "@oceantech/oceantech-ui";
import React, { useContext, useEffect, useState } from "react";
import ConfirmDialog from "../../component/dialog/OCTConfirmDialog";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { IObject } from "../../models/models";
import { FORM_CODE_SERVICE, INIT_SEARCH_OBJECT } from "../../constant";
import { PAGINATION, PATH_ROUTES } from "../../../Constant";
import { RESPONSE_STATUS_CODE, TYPE } from "../../utils/Constant";
import { Button, ButtonGroup } from "react-bootstrap";
import CustomTooltip from "../../component/CustomTooltip";
import {
  deletePackage,
  exportToExcelPackageList,
  getListPackage,
} from "../services/ManagerPackage";
import AppContext from "../../../AppContext";
import {
  functionExportToExcel,
  handleThrowResponseMessage,
} from "../../../AppFunction";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { convertNumberPrice } from "../../utils/FunctionUtils";
import ButtonImportExcel from "../../component/ButtonImportExcel";
import ButtonExportExcel from "../../component/ButtonExportExcel";
import PackageDialog from "./Dialog/PackageDialog";

type Props = {};

const PackageList = (props: Props) => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const [shouldOpenConfirmDeleteDialog, setShouldOpenConfirmDeleteDialog] =
    useState<boolean>(false);
  const [packageList, setPackageList] = useState<any[]>([]);
  const [searchObject, setSearchObject] = useState<IObject>(INIT_SEARCH_OBJECT);
  const [totalPage, setTotalPage] = useState<number>(
    PAGINATION.TOTAL_PAGE_DEFAULT
  );
  const [totalElements, setTotalElements] = useState<number>(
    PAGINATION.TOTAL_ELEMENTS_DEFAULT
  );
  const [id, setId] = useState<string>("");
  const [openPackageDialog, setOpenPackageDialog] = useState<boolean>(false);
  const [isView, setIsView] = useState<boolean>(false);
  const navigateTo = useNavigate();
  const [packageInfo, setPackageInfo] = useState<any>({});

  const packageDefaultPath = PATH_ROUTES.SYSTEM.SERVICE;

  useEffect(() => {
    localStorage.removeItem(`form-${FORM_CODE_SERVICE.DICH_VU_KY_THUAT.code}`);
  }, []);

  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "stt",
      render: (row, index, stt) => <span>{stt}</span>,
    },
    {
      name: "Mã gói khám",
      field: "code",
      headerStyle: {
        minWidth: "90px",
      },
      cellStyle: {
        textAlign: "center",
      },
      // render: (row) => <span>{row?.username}</span>,
    },
    {
      name: "Tên gói khám",
      field: "name",
      headerStyle: {
        minWidth: "250px",
      },
      cellStyle: {
        textAlign: "left",
      },
      // render: (row) => <span>{row?.roles?.toString()}</span>,
    },
    {
      name: "Đơn giá",
      field: "svcPrice",
      cellStyle: {
        textAlign: "right",
      },
      render: (row) => (
        <span>{convertNumberPrice(+row?.attributes?.svcPrice || 0)}</span>
      ),
    },
    {
      name: "Giá viện phí",
      field: "svcFeeHospital",
      cellStyle: {
        textAlign: "right",
      },
      render: (row) => (
        <span>{convertNumberPrice(+row?.attributes?.svcFeeHospital || 0)}</span>
      ),
    },
    {
      name: "Giá BHYT",
      field: "svcFeeIns",
      cellStyle: {
        textAlign: "right",
      },
      render: (row) => (
        <span>{convertNumberPrice(+row?.attributes?.svcFeeIns || 0)}</span>
      ),
    },
    {
      name: "Giá dịch vụ",
      field: "svcFee",
      cellStyle: {
        textAlign: "right",
      },
      render: (row) => (
        <span>{convertNumberPrice(+row?.attributes?.svcFee || 0)}</span>
      ),
    },
    {
      name: "Phòng thực hiện",
      field: "svcFee",
      cellStyle: {
        textAlign: "left",
      },
      render: (row) => <span>{row?.attributes?.deptLaboratoryName}</span>,
    },
  ];

  const actionsColumn: any[] = [
    {
      name: lang("TABLE.ACTION"),
      field: "action",
      isVisible: true,
      headerStyle: {
        width: 100,
        // ...actionStyle,
      },
      cellStyle: {
        // width: 100,
        // ...actionStyle,
      },
      render: (rowData: any) => (
        <div>
          <ButtonGroup size="sm">
            <CustomTooltip title={lang("BTN.DELETE")}>
              <Button
                onClick={() => {
                  setShouldOpenConfirmDeleteDialog(true);
                  setId(rowData?.id);
                }}
                variant="outline-danger"
                className="hover-white"
              >
                <i className="bi bi-trash-fill px-0" color="inherit"></i>
              </Button>
            </CustomTooltip>
            <CustomTooltip title={lang("BTN.EDIT")}>
              <Button
                onClick={() => {
                  setIsView(false);
                  handleEditPackage?.(rowData);
                }}
                variant="outline-warning"
                className="hover-white"
              >
                <i className="bi bi-pencil-fill px-0"></i>
              </Button>
            </CustomTooltip>
            <CustomTooltip title={lang("BTN.VIEW")}>
              <Button
                onClick={() => {
                  setIsView(true);
                  handleEditPackage?.(rowData);
                }}
                variant="outline-primary"
                className="hover-white"
              >
                <i className="bi bi-eye-fill px-0" color="inherit"></i>
              </Button>
            </CustomTooltip>
          </ButtonGroup>
        </div>
      ),
    },
  ];

  const handleGetPackageList = async () => {
    try {
      setPageLoading(true);
      const { data } = await getListPackage(searchObject);
      if (RESPONSE_STATUS_CODE.SUCCESS === data.code) {
        setPackageList(data?.data?.content);
        let totalElementsValue = data?.data?.totalElements || 0;
        let totalPageValue =
          totalElementsValue % searchObject?.pageSize === 0
            ? totalElementsValue / searchObject?.pageSize
            : Math.floor(totalElementsValue / searchObject?.pageSize) + 1;
        setTotalPage(totalPageValue || 0);
        setTotalElements(data?.data?.totalElements || 0);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    handleGetPackageList();
  }, [searchObject]);

  const handleEditPackage = async (row: any) => {
    handleOpenPackageDialog();
    try {
      setPackageInfo({
        ...row,
        ...row?.attributes,
        deptLaboratory: {
          name: row?.attributes?.deptLaboratoryName,
          code: row?.attributes?.deptLaboratoryCode,
        },
      });
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
      handleOpenPackageDialog();
    }
  };

  const loadListPackage = async () => {
    // Your logic to fetch data
  };

  const handleOpenPackageDialog = () => {
    setOpenPackageDialog(true);
  };

  const handleDelete = async (id: string) => {
    try {
      setPageLoading(true);
      const res = await deletePackage(id);
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
      } else {
        handleThrowResponseMessage(res);
      }
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
      setShouldOpenConfirmDeleteDialog(false);
      await handleGetPackageList();
    }
  };

  const handleClosePackageDialog = () => {
    setOpenPackageDialog(false);
    setPackageInfo({});
    setIsView(false);
    handleGetPackageList();
  };

  const handleExportExcel = async () => {
    setPageLoading(true);
    try {
      await functionExportToExcel(
        exportToExcelPackageList,
        searchObject,
        "Danh sách dịch vụ kỹ thuật.xlsx"
      );
    } catch (error) {
      console.log(error);
      toast.error("Có lỗi xảy ra, vui lòng thử lại!");
    } finally {
      setPageLoading(false);
    }
  };

  const rowElement = document.querySelector(".table-toolbar .row .flex-end");
  const newDivBtnExport = document.querySelector(".btn-export-excel");
  const newDivBtnImport = document.querySelector(".btn-import-excel");
  if (rowElement && newDivBtnExport && newDivBtnImport) {
    rowElement.insertBefore(
      newDivBtnExport,
      rowElement.children[rowElement.children.length]
    );
    rowElement.insertBefore(
      newDivBtnImport,
      rowElement.children[rowElement.children.length]
    );
  }

  return (
    <div>
      <OctTable
        title={lang("Danh sách gói khám")}
        data={packageList}
        columns={columns}
        searchObject={searchObject}
        setSearchObject={setSearchObject}
        isActionTableTab
        buttonAdd
        scrollable
        isSearchable
        handleSearch={loadListPackage}
        notDelete
        handleOpenDialog={handleOpenPackageDialog}
        totalPages={totalPage}
        totalElements={totalElements}
        type={TYPE.MULTILINE}
        actionsColumn={actionsColumn}
        className="custom-list-data"
      />
      <ButtonExportExcel
        className="btn-export-excel"
        handleExportExcel={handleExportExcel}
        buttonName="Xuất gói khám"
      />
      <ButtonImportExcel
        buttonName="Nhập gói khám"
        className="btn-import-excel"
        url="/package/import"
        handleOKEditClose={handleGetPackageList}
      />
      {openPackageDialog && (
        <PackageDialog
          isView={isView}
          handleCloseDialog={handleClosePackageDialog}
          packageInfo={packageInfo}
        />
      )}
      {shouldOpenConfirmDeleteDialog && (
        <ConfirmDialog
          show={shouldOpenConfirmDeleteDialog}
          title={"Xác nhận xóa"}
          message={"Bạn có muốn xóa không?"}
          yes={"Xác nhận"}
          onYesClick={() => handleDelete(id)}
          cancel={"Hủy"}
          onCancelClick={() => setShouldOpenConfirmDeleteDialog(false)}
        />
      )}
    </div>
  );
};

export default PackageList;
