import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import AppContext from "../../AppContext";
import { localStorageItem } from "../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../auth/core/_consts";
import axios from "axios";
import { RESPONSE_STATUS_CODE } from "../utils/Constant";
import { toast } from "react-toastify";

interface IProps {
  handleExportExcel?: () => void;
  className?: string;
  buttonName?: string;
}

function ButtonExportExcel(props: IProps) {
  const { handleExportExcel = () => {}, className = "", buttonName="" } = props;
  const { setPageLoading } = useContext(AppContext);


  return (
    <div className={`${className} spaces mx-5`}>
      <Button className="spaces h-32" size="sm" onClick={handleExportExcel}>
        <div className="flex flex-middle">
          <i className="bi bi-download"></i>
          <p className="spaces fs-14 m-0 w-max-content">{buttonName || "Export Excel"}</p>
        </div>
      </Button>
    </div>
  );
}

export default ButtonExportExcel;
