import {toast} from "react-toastify";
import {DATE_FORMAT, RESPONSE_CODE, VARIABLE} from "./Constant";
import {localStorageItem} from "./modules/utils/LocalStorage";
import moment from "moment";
import {KEY_LOCALSTORAGE} from "./modules/auth/core/_consts";
import {useIntl} from "react-intl";
import React, { useEffect, useState } from "react";
import {KEY, TYPE} from "./modules/utils/Constant";
import {columnNamesType} from "@oceantech/oceantech-ui";
import { getJsonGenerateForm } from "./modules/services";
import { saveAs } from 'file-saver';
import { getDataFromIndexedDB, openDatabaseByName, saveDataToIndexedDB } from "./modules/utils/IndexedDB";
import { ERROR_MESSAGE, INDEX_DB_NAME, SUCCESS_CODE } from "./modules/constant";
import { COMPONENT_TYPE } from "./modules/component/generate-form/GenerateFormConfig";
import Fuse, { IFuseOptions } from "fuse.js";

export function TranslateMessage (messageId?: string) {
  const intl = useIntl();
  return intl.formatMessage({ id: messageId || "" });
}

export const handleThrowResponseMessage = (res: any) => {
  // todo: Trả ra message theo 2 loại message của response
  const { message, data, code } = res?.data;
  const singleMessageKey = VARIABLE.FUNCTION_CONSTANT.ERROR_MESSAGE;
  const multipleMessageKey = VARIABLE.FUNCTION_CONSTANT.MESSAGE;

  toast.clearWaitingQueue();
  if(handleMessageByResponseCode(code)) {
    return;
  }

  let firstObject = data?.[0];
  if (Object.keys(firstObject).includes(singleMessageKey)) {
    //case:  data = [{errorMessage: "..."}]
    toast.warning(
      firstObject[singleMessageKey] ? firstObject[singleMessageKey] : message //case:  data = [{errorMessage: null}]
    );
  } else if (Object.keys(firstObject).includes(multipleMessageKey)) {
    data.forEach((x: any) => {
      toast.warning(x.field + ": " + x[multipleMessageKey]);
    });
  } else {
    toast.error("GENERAL.ERROR");
  }
};

const handleMessageByResponseCode = (code?: number) => {
  switch (code) {
    case RESPONSE_CODE.FORBIDDEN:
      toast.warning(TranslateMessage("TOAST.ERROR.FORBIDDEN"));
      return true;
    case RESPONSE_CODE.METHOD_NOT_ALLOWED:
      toast.warning(TranslateMessage("TOAST.ERROR.METHOD_NOT_ALLOWED"));
      return true;
    case RESPONSE_CODE.CONFLICT:
      toast.warning(TranslateMessage("TOAST.ERROR.CONFLICT"));
      return true;
    default:
      return false;
  }
}

export const getCurrentUserInformation = () => {
  let currentUser = localStorageItem.get(KEY_LOCALSTORAGE.CURRENT_USER);

  return {
    currentUser: currentUser ? currentUser : {},
  }
}

export const formatDateTable = (date: any) => {
  let newDate = new Date(date);
  return date && moment(newDate).isValid() ? moment(date).format(DATE_FORMAT.TABLE) : null;
};

export const isSuccessfulResponse = (code: number) => RESPONSE_CODE.SUCCESS === code;

export const isEven = (number: number) => number % 2 === 0;

export const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, callBack: () => void) => {
  if (KEY.ENTER === event.key) {
    callBack()
  }
}

export const flatArrayByChildrenName = (arr: any, childName: string) => {
  let result: any = [];

  arr.forEach((item: any) => {
    result.push(item);
    if (item[childName] && item[childName].length > 0) {
      result = result.concat(flatArrayByChildrenName(item[childName], childName));
    }
  });

  return result;
}

export const getColumnsAlignByType = (column: columnNamesType) => {
  switch (column.type) {
    case TYPE.DATE:
      return "center";
    case TYPE.NUMBER:
      return "center";
    case TYPE.MONEY:
      return "right";
    case TYPE.TEXT:
      return "left";
    default:
      return "left";
  }
}

export const handleKeyUp = (event: React.ChangeEvent<HTMLInputElement>, callBack: () => void) => {
  if (!event.target.value) {
    callBack()
  }
}

export const generateForm = async (codeAPI: string, setIsLoading: any) => {
  setIsLoading(true);
  try {
    let dataFieldAuto = localStorage.getItem(`form-${codeAPI}`);
    if (dataFieldAuto) {
      return JSON.parse(dataFieldAuto) || {};
    } else {
      let res = await getJsonGenerateForm(codeAPI);
      if (res?.data?.code === RESPONSE_CODE.SUCCESS) {
        localStorage.setItem(`form-${codeAPI}`, res?.data?.data);
        return JSON.parse(res?.data?.data);
      } else {
        toast.error("Có lỗi xảy ra, vui lòng thử lại!");
      }
    }
  } catch (error) {
    toast.error("Có lỗi xảy ra, vui lòng thử lại!");
  } finally {
    setIsLoading(false);
  }
};

export const functionExportToExcel = async (
  apiFunction = (searchObject: any) => {},
  searchObject: any,
  fileName: string,
  setPageLoading = (isLoading: boolean) => {}
) => {
  try {
    setPageLoading(true);
    let res: any = await apiFunction(searchObject);
    if (!isSuccessfulResponse(res?.status)) {
      return toast.error("Có lỗi xảy ra, vui lòng thử lại!");
    }
    try {
      let newData = new Blob([res?.data], { type: "application/json" });
      let data = JSON.parse(await newData.text());
      if (!isSuccessfulResponse(data?.code)) {
        handleThrowResponseMessage({ data });
      }
    } catch (e) {
      console.error(e);
      toast.success("Export thành công");
      let blob = new Blob([res?.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, fileName);
    }
  } catch (e) {
    console.error(e);
    toast.error("Có lỗi xảy ra, vui lòng thử lại!");
  } finally {
    setPageLoading(false);
  }
};

export const formatStringDateDTO = (date: string | Date | undefined) => {
  return date ? moment(date).format("YYYY-MM-DDTHH:mm:ss") : "";
};

export const generateDataByUrl = async (
  codeAPI: string,
  funcApi: () => Promise<any>,
  setIsLoading?: (isLoading: boolean) => void
) => {
  setIsLoading && setIsLoading(true);
  try {
    const db = await openDatabaseByName(INDEX_DB_NAME.URL_DATA);
    let dataFieldAuto = await getDataFromIndexedDB(db, codeAPI, INDEX_DB_NAME.URL_DATA);

    if (dataFieldAuto) {
      return JSON.parse(dataFieldAuto) || {};
    } else {
      const res = await funcApi();
      if (res?.data?.code === SUCCESS_CODE) {
        const dataToStore = res.data.data;

        if (dataToStore) {
          await saveDataToIndexedDB(db, codeAPI, JSON.stringify(res), INDEX_DB_NAME.URL_DATA);
          return res;
        } else {
          toast.error(ERROR_MESSAGE);
          return {};
        }
      } else {
        toast.error(ERROR_MESSAGE);
      }
    }
  } catch (error) {
    console.error("Error:", error);
    toast.error(ERROR_MESSAGE);
  } finally {
    setIsLoading && setIsLoading(false);
  }
};

export const useDebounce = <T,>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    
      return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const generateSearchParams = (data: any, formCode?: string) => {
  const handleValues = () => {
    let searchParams: any = {};

    const checkValue = (lstData: any) => {
      let newLstData = lstData?.components?.filter(
        (item: any) => item.type !== COMPONENT_TYPE.BUTTON
      );
      newLstData?.forEach((data: any) => {
        switch (data.type) {
          case COMPONENT_TYPE.SELECT:
            if (data?.data?.headers?.length) {
              let ListKeySearch = {};
              data?.data?.headers?.length && data?.data?.headers.map((item: any, index: any) => {
                ListKeySearch = {
                  ...ListKeySearch,
                  [item.key]: item.value || ""
                }
              })
              searchParams = {
                ...searchParams,
                [data?.key]: ListKeySearch
              };
            }
            break;
          case COMPONENT_TYPE.COLUMNS:
            data?.columns?.forEach((lstData: any) => {
              checkValue(lstData);
            });
            break
          default:
            break;
        };
      });
    };

    checkValue(data);
    return formCode ? { [formCode]: searchParams } : searchParams;
  };

  return handleValues();
};

export const formatMoney = (value: number) => {
  if (!value) {
    return 0;
  } else {
    let result = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return result;
  }
};

export const handleSearchByFuse = (listData: any[], textSearch: string, setListData: (data: any) => void, 
  extraOptions?: IFuseOptions<any>) => {
  const fuseOptions = {
    isCaseSensitive: false, //phân biệt chữ hoa chữ thường
    includeScore: true,
    shouldSort: true,
    includeMatches: false,
    findAllMatches: false,
    minMatchCharLength: 2, //kết quả trả về cả những phần tử khớp theo số lượng ký tự truyền vào
    // location: 0,
    // threshold: 1,
    // distance: 100,
    useExtendedSearch: true, //search nâng cao, phụ thuộc các dấu =, ", '
    ignoreLocation: true,
    ignoreFieldNorm: true,
    fieldNormWeight: 1,
    keys: ["code", "name"],
    ...extraOptions
  };    

  const fuse = new Fuse(listData, fuseOptions);
  if(textSearch !== "") {
    const lstServices = fuse.search(textSearch).map((ser: any) => {
      return ser?.item;
    });
    setListData(lstServices);
  } else {
    setListData(listData);
  }
}

