import {PERMISSIONS, AUTHORITIES, PATH_ROUTES} from "../../Constant"

export type TMenu = {
  title: string,
  to: string,
  name: string,
  permission: string,
  ability: string,
  subMenu: TSubMenu[],
  icon: string
}

export type TSubMenu = {
  title: string,
  to: string,
  hasBullet?: boolean,
  icon: string
  permission: string,
  ability: string,
  subMenu?: TSubMenu[]
}

export const allMenu: TMenu[] = [
  {
    title: "GENERAL.SYSTEM",
    to: PATH_ROUTES.SYSTEM.DEFAULT,
    name: "system",
    permission: PERMISSIONS.ADMIN,
    ability: AUTHORITIES.AUTHORITY.VIEW,
    icon: "/media/icons/homepage/system.svg",
    subMenu: [
      {
        title: "USER",
        to: PATH_ROUTES.SYSTEM.USER,
        hasBullet: false,
        icon: "/media/icons/user.svg",
        permission: PERMISSIONS.ADMIN,
        ability: AUTHORITIES.USER.VIEW,
      },
      {
        title: "ROLE",
        to: PATH_ROUTES.SYSTEM.ROLE,
        hasBullet: false,
        icon: "/media/icons/role.svg",
        permission: PERMISSIONS.ADMIN,
        ability: AUTHORITIES.ROLE.VIEW,
      },
      {
        title: "AUTH",
        to: PATH_ROUTES.SYSTEM.AUTHORITY,
        hasBullet: false,
        icon: "/media/icons/authority.svg",
        permission: PERMISSIONS.ADMIN,
        ability: AUTHORITIES.AUTHORITY.VIEW,
      },
      {
        title: "UNIT",
        to: PATH_ROUTES.SYSTEM.ORGANIZATION,
        hasBullet: false,
        icon: "/media/icons/organization.svg",
        permission: PERMISSIONS.ADMIN,
        ability: AUTHORITIES.ORGANIZATION.VIEW,
      },
      {
        title: "DEPARTMENT",
        to: PATH_ROUTES.SYSTEM.DEPARTMENT,
        hasBullet: false,
        icon: "/media/icons/homepage/system.svg",
        permission: PERMISSIONS.ADMIN,
        ability: AUTHORITIES.DEPARTMENT.VIEW,
      },
      {
        title: "USER.INFOR",
        to: PATH_ROUTES.SYSTEM.PERSON,
        hasBullet: false,
        icon: "/media/icons/person.svg",
        permission: PERMISSIONS.ADMIN,
        ability: AUTHORITIES.PERSON.VIEW,
      },
      {
        title: "Dịch vụ kỹ thuật",
        to: PATH_ROUTES.SYSTEM.SERVICE,
        hasBullet: false,
        icon: "/media/icons/homepage/system.svg",
        permission: PERMISSIONS.ADMIN,
        ability: AUTHORITIES.PERSON.VIEW,
      },
      {
        title: "Gói khám",
        to: PATH_ROUTES.SYSTEM.PACKAGE,
        hasBullet: false,
        icon: "/media/icons/homepage/system.svg",
        permission: PERMISSIONS.ADMIN,
        ability: AUTHORITIES.PERSON.VIEW,
      },
      {
        title: "Trang thiết bị y tế",
        to: PATH_ROUTES.SYSTEM.EQUIPMENT,
        hasBullet: false,
        icon: "/media/icons/person.svg",
        permission: PERMISSIONS.ADMIN,
        ability: AUTHORITIES.EQUIPMENT.VIEW,
      },
      {
        title: "Nhà cung cấp",
        to: PATH_ROUTES.SYSTEM.SUPPLIER,
        hasBullet: false,
        icon: "/media/icons/person.svg",
        permission: PERMISSIONS.ADMIN,
        ability: AUTHORITIES.SUPPLIER.VIEW,
      },
    ]
  },
]