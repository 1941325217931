import React, { FocusEvent } from "react";
import { Column } from "react-table";
import { TableCustomHeader } from "../../../component/table/components/TableCustomHeader";
import CustomIconButton from "../custom-icon-button/CustomIconButton";
import { TableCustomCell } from "../../../component/table/components/TableCustomCell";
import { Formik } from "formik";
import AutocompleteV2 from "../../../component/AutocompleteObjectV2";
import { formatMoney } from "../../../../AppFunction";
import TextField from "../TextField";
import NumberField from "../number-field";

const dsDichVuColumnsSimple = [
  {
    name: "",
    field: "checkbox",
    width: 50,
    minWidth: 27,
  },
  {
    name: "Mã DV",
    field: "code",
    width: 200,
    minWidth: 75,
    textAlign: "center",
    // highlight: true
  },
  {
    name: "Tên dịch vụ",
    field: "name",
    width: 500,
    minWidth: 200,
    textAlign: "left",
    // highlight: true,
  },
  {
    name: "SL",
    field: "svcQuantity",
    width: 90,
    minWidth: 50,
    textAlign: "center",
    render: (rowData: any) => {
      return <span>{rowData?.attributes?.svcQuantity}</span>;
    },
  },
  {
    name: "Giá BHYT",
    field: "svcFeeIns",
    width: 180,
    minWidth: 75,
    textAlign: "end",
    render: (rowData: any) => {
      return <span>{rowData?.attributes?.svcFeeIns}</span>;
    },
  },
  {
    name: "Giá viện phí",
    field: "svcFeeHospital",
    width: 180,
    minWidth: 80,
    textAlign: "end",
    render: (rowData: any) => {
      return <span>{rowData?.attributes?.svcFeeHospital}</span>;
    },
  },
  {
    name: "Giá dịch vụ",
    field: "svcFee",
    width: 180,
    minWidth: 80,
    textAlign: "end",
    render: (rowData: any) => {
      return <span>{rowData?.attributes?.svcFee}</span>;
    },
  },
  {
    name: "Đơn giá",
    field: "svcPrice",
    width: 150,
    minWidth: 70,
    textAlign: "end",
    render: (rowData: any) => {
      return <span>{rowData?.attributes?.svcPrice}</span>;
    },
  },
  {
    name: "Phòng thực hiện",
    field: "deptLaboratoryName",
    width: 250,
    minWidth: 110,
    render: (rowData: any) => {
      return <span>{rowData?.attributes?.deptLaboratoryName}</span>;
    },
  },
];

const DsDichVuChiDinhColumn = (
  handleRenderPrice: (data: any, typeObjectCode: string) => number,
  selectedRowIndex?: number | null,
  setSelectedRow?: React.Dispatch<React.SetStateAction<number | null>>,
  selectedCol?: any,
  setSelectedCol?: React.Dispatch<React.SetStateAction<any>>,
  listDoiTuong?: any[],
  handleChangeOrders?: (field: string, data: any) => void,
  handleDelete?: () => void,
  isView?: boolean
): ReadonlyArray<Column<any>> => {
  return [
    ...(isView
      ? []
      : [
          {
            Header: (props: any) => (
              <TableCustomHeader<any>
                tableProps={props}
                title="Thao tác"
                className="text-center text-transform-none text-light min-w-75px fs-12"
              />
            ),
            id: "Thao tác",
            Cell: ({ ...props }) => (
              <div className="d-flex justify-content-center">
                <CustomIconButton
                  variant="delete"
                  onClick={() => {
                    handleDelete?.();
                    setSelectedRow?.(props?.row?.index);
                  }}
                >
                  <i className="bi bi-trash3-fill text-danger"></i>
                </CustomIconButton>
              </div>
            ),
          },
        ]),
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"STT"}
          className="text-center text-transform-none text-light min-w-40px fs-12"
        />
      ),
      id: "STT",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center "
          data={props?.row?.index + 1}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Mã DV"}
          className="text-center text-transform-none text-light min-w-100px fs-12"
        />
      ),
      id: "Mã dịch vụ",
      Cell: ({ ...props }) => (
        <TableCustomCell data={props?.data[props?.row?.index].code} />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Tên dịch vụ"}
          className="text-center text-transform-none text-light min-w-250px fs-12"
        />
      ),
      id: "Tên dịch vụ",
      Cell: ({ ...props }) => {
        let data = props?.data[props?.row?.index].name;
        return (
          <TableCustomCell
            className="spaces max-w-250 text-truncate"
            data={data}
          />
        );
      },
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Đối tượng"}
          className="text-center text-transform-none text-light min-w-200px fs-12"
        />
      ),
      id: "Đối tượng",
      Cell: ({ ...props }) => {
        let rowData = props?.data[props?.row?.index];
        return (
          <TableCustomCell
            className=""
            data={
              <div className={`spaces h-28`}>
                {rowData?.visitObjectType?.name || ""}
              </div>
            }
          />
        );
      },
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Số lượng"}
          className="text-center text-transform-none text-light min-w-100px fs-12"
        />
      ),
      id: "Số lượng",
      Cell: ({ ...props }) => {
        let data = props?.data[props?.row?.index]?.attributes?.svcQuantity || 1;
        return <TableCustomCell className="text-center " data={data} />;
      },
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Đơn giá"}
          className="text-center text-transform-none text-light min-w-100px fs-12"
        />
      ),
      id: "Đơn giá",
      Cell: ({ ...props }) => {
        let rowData = props?.data[props?.row?.index];
        let colData = props?.column;
        return (
          <TableCustomCell
            className=""
            data={
              <Formik initialValues={{}} onSubmit={() => {}}>
                {selectedRowIndex === props?.row?.index &&
                colData?.id === selectedCol?.id && !isView ? (
                  <NumberField
                    value={rowData?.feePackage}
                    name="feePackage"
                    onBlur={(e: any) => {}}
                    onChange={(value) => {
                      handleChangeOrders?.("feePackage", value);
                    }}
                    autoFocus={selectedRowIndex === props?.row?.index}
                    className="input text-end autocomplete-custom-tiep-nhan radius spaces width-100 h-25"
                  />
                ) : (
                  <div
                    className={`spaces text-end`}
                    onDoubleClick={() => {
                      setSelectedRow?.(props?.row?.index);
                      setSelectedCol?.(colData);
                    }}
                  >
                    {rowData?.feePackage
                      ? formatMoney(+rowData?.feePackage)
                      : 0}
                  </div>
                )}
              </Formik>
            }
          />
        );
      },
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Thành tiền"}
          className="text-center text-transform-none text-light min-w-100px fs-12"
        />
      ),
      id: "Thành tiền",
      Cell: ({ ...props }) => {
        let rowData = props?.data[props?.row?.index];
        let visitObjectTypeCode = rowData?.visitObjectType?.code;
        let data = rowData?.feePackage * (rowData?.attributes?.svcQuantity || 1);
        return <TableCustomCell className="text-end " data={data ? formatMoney(data) : 0} />;
      },
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Ghi chú"}
          className="text-center text-transform-none text-light min-w-200px fs-12"
        />
      ),
      id: "Ghi chú",
      Cell: ({ ...props }) => {
        let rowData = props?.data[props?.row?.index];
        let colData = props?.column;
        return (
          <TableCustomCell
            className=""
            data={
              <Formik initialValues={{}} onSubmit={() => {}}>
                {selectedRowIndex === props?.row?.index &&
                colData?.id === selectedCol?.id && !isView ? (
                  <TextField
                    className="w-100"
                    inputClassName="w-100"
                    type="text"
                    name="notes"
                    defaultValue={rowData?.notes || ""}
                    onBlur={(e: FocusEvent<HTMLInputElement>) => {
                      handleChangeOrders?.("notes", e?.target?.value);
                      setSelectedRow?.(null);
                      setSelectedCol?.(null);
                    }}
                    autoFocus={true}
                  />
                ) : (
                  <div
                    className={`spaces h-28`}
                    onDoubleClick={() => {
                      setSelectedRow?.(props?.row?.index);
                      setSelectedCol?.(colData);
                    }}
                  >
                    {rowData?.notes || ""}
                  </div>
                )}
              </Formik>
            }
          />
        );
      },
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Phòng thực hiện"}
          className="text-center text-transform-none text-light min-w-200px fs-12"
        />
      ),
      id: "Phòng thực hiện",
      Cell: ({ ...props }) => {
        let data =
          props?.data[props?.row?.index]?.attributes?.deptLaboratoryName;
        return <TableCustomCell className="" data={data} />;
      },
    },
  ];
};

export { dsDichVuColumnsSimple, DsDichVuChiDinhColumn };
