
export const inputTypeList = {
    text: 'text',
    select: 'select',
    date: 'date',
    number: 'number',
    file: 'file',
    checkbox: 'checkbox'
};

export const TYPE_CATEGORY ={
    cap:1,
    bac:2,
    chucVuDang:3,
    chucVuDoan:4,
    chuyenNganh:5,
    phuCap:6,
    danToc: 7,
    chucDanh:8,
    phongBan: 9,
    nganHang: 10,
    binhChung: 11,
    chungChi:12,
    tonGiao:13,
    capBacQuanSu: 14,
    chucVuQuanSu: 15,
    hangThuongBenhBinh: 16,
    phongBenh: 17,
    viTriCongViec:28,
    noiDaoTao:31,
    donVi:100,
    nhomChungChi: 34,
    trinhDoDaoTao: 35,
    quanHeGiaDinh: 36,
    kyNangMem: 37,
    trinhDoNgoaiNgu: 38,
    trinhDoTinHoc: 39,
    trinhDoQuanLyNhaNuoc: 40,
    trinhDoLyLuan: 41,
    hinhThucKhenThuong: 42,
    danhHieu: 43,
    loaiThuTuc: 44,
    loaiDieuDong: 45,
    phanLoaiBaoCao: 46
};

export const rowPerPage = [1,5,10,20,30];

export const regex = {
    phone: /^(?:\+84|0)(?:3|5|7|8|9)\d{8}$/,
}

export const INIT_SEARCH_OBJECT = {
    keyword: '',
    pageIndex: 1,
    pageSize: 10
}

export const SUCCESS_CODE = 200;

export const STATUS_NV = {
    DANG_LAM_VIEC : 1,
    THOI_VIEC: 2,
    DINH_CHI_CONG_TAC: 3,
    NGHI_CHE_DO: 4,
    NGHI_KHONG_LUONG: 5
}

export const LIST_STATUS_NV = [
    { code: STATUS_NV.DANG_LAM_VIEC, name: "Đang làm việc", styleClass: " bg-green" },
    { code: STATUS_NV.THOI_VIEC, name: "Thôi việc", styleClass: "bg-slate-gray" },
    { code: STATUS_NV.DINH_CHI_CONG_TAC, name: "Đình chỉ công tác", styleClass: "bg-coral" },
    { code: STATUS_NV.NGHI_CHE_DO, name: "Nghỉ chế độ", styleClass: "bg-amber" },
    { code: STATUS_NV.NGHI_KHONG_LUONG, name: "Nghỉ không lương", styleClass: "bg-purple" },
]

export enum TYPE_OF {
    NUMBER = "number",
    STRING = "string",
    OBJECT = "object"
}

export const FORM_CODE_SERVICE = {
    DICH_VU_KY_THUAT: {
        code: 'serviceInfo',
        tabIndex: 0
    },
    KET_QUA_CLS: {
        code: 'paraclinicalResult',
        tabIndex: 1
    },
    CHI_SO_CLS: {
        code: 'paraclinicalIndicator',
        tabIndex: 2
    },
    BANG_GIA_YEU_CAU: {
        code: 'requestPrice',
        tabIndex: 3
    },
    THONG_TIN_KHAC: {
        code: 'additionInfo',
        tabIndex: 4
    },
}

export const FORM_CODE_SERVICE_ARRAY = [
    {
        code: 'serviceInfo',
        tabIndex: 0,        
        hasForm: true
    },
     {
        code: 'paraclinicalResult',
        tabIndex: 1,
    },
    {
        code: 'paraclinicalIndicator',
        tabIndex: 2,
    },
    {
        code: 'requestPrice',
        tabIndex: 3,
        hasForm: true
    },
    {
        code: 'additionInfo',
        tabIndex: 4,
        hasForm: true
    },
]

export const LIST_ATTRIBUTES = {
    svcFeeIns: {
        code: 1,
        name: 'svcFeeIns',
    },
    svcFeeHospital: {
        code: 2,
        name: 'svcFeeHospital',
    },
    svcFee: {
        code: 3,
        name: 'svcFee',
    },
    svcPrice: {
        code: 4,
        name: 'svcPrice',
    },
    svcQuantity: {
        code: 5,
        name: 'svcQuantity',
    },
    deptLaboratoryName: {
        code: 6,
        name: 'deptLaboratoryName',
    },
    deptLaboratoryCode: {
        code: 7,
        name: 'deptLaboratoryCode',
    },
    specimen: {
        code: 8,
        name: 'specimen',
    },
    deptClinicCode: {
        code: 9,
        name: 'deptClinicCode',
    },
    deptClinicName: {
        code: 10,
        name: 'deptClinicName',
    },
    deptImagingDiagnosticCode: {
        code: 11,
        name: 'deptImagingDiagnosticCode',
    },
    deptImagingDiagnosticName: {
        code: 12,
        name: 'deptImagingDiagnosticName',
    },
}

export const LIST_ATTRIBUTES_ARRAY = [
    {
        code: 1,
        name: 'svcFeeIns',
    },
    {
        code: 2,
        name: 'svcFeeHospital',
    },
    {
        code: 3,
        name: 'svcFee',
    },
    {
        code: 4,
        name: 'svcPrice',
    },
    {
        code: 5,
        name: 'svcQuantity',
    },
    {
        code: 6,
        name: 'deptLaboratoryName',
    },
    {
        code: 7,
        name: 'deptLaboratoryCode',
    },
    {
        code: 8,
        name: 'specimen',
    },
    {
        code: 9,
        name: 'deptClinicCode',
    },
    {
        code: 10,
        name: 'deptClinicName',
    },
    {
        code: 11,
        name: 'deptImagingDiagnosticCode',
    },
    {
        code: 12,
        name: 'deptImagingDiagnosticName',
    },
]
    
export const SIMPLE_CATEGORY_TYPE = {
    DEPARTMENT_TYPE: 41,
    DEPARTMENT_LEVEL: 42,
  }

export const INDEX_DB_NAME = {
    FORM_MODEL:{ namePrefix:"form-", name: "formModel", keyPath: "codeAPI" },
    URL_DATA: { namePrefix:"", name: "urlData", keyPath: "url" },
};

export const ERROR_MESSAGE = "Có lỗi xảy ra, vui lòng thử lại!"

export const TERM_CLASS_IDS = {
    KHAM_BENH: 1,
    XET_NGHIEM: 2,
    ICD10: 4,
    CDHA: 5,
    PTTT: 6,
    KQDT: 7,
    HTXT: 8,
    LOAI_MO_BENH_AN: 11,
    HT_CHUYEN_TUYEN: 15,
    LD_CHUYEN_TUYEN: 16,
    LD_CHUYEN_TUYEN_BS: 17,
    CHUYEN_TUYEN: 18,
  };