import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { generateForm } from "../../../../AppFunction";
import { FORM_CODE_SERVICE } from "../../../constant";
import AppContext from "../../../../AppContext";
import { Formik, Form } from "formik";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import * as Yup from "yup";
import GenerateFormHTML from "../../../component/generate-form/GenerateFormHTML";

type Props = {
  handleCloseDialog: () => void;
  isView?: boolean;
  paraclinicalResult: any;
  formName: string;
  listNguong: any;
  setListNguong: (listItem: any) => void;
};

const initialValuesService: any = {
  paraclinicalResult: {
    name: "",
    code: "",
  },
};

const ParaclinicalResultDialog = (props: Props) => {
  const { lang } = useMultiLanguage();
  const {
    handleCloseDialog,
    isView = false,
    paraclinicalResult,
    formName,
    listNguong,
    setListNguong,
  } = props;
  const { setPageLoading } = useContext(AppContext);
  const [dataGenerate, setDataGenerate] = useState();

  const formikRef = useRef<any>(null);

  let validationSchema = Yup.object({
    paraclinicalResult: Yup.object({
      name: Yup.string().required("Bắt buộc nhập").nullable(),
      code: Yup.string().required("Bắt buộc nhập").nullable(),
    }),
  });

  const getForm = async (code: string) => {
    let data = await generateForm(code, setPageLoading);
    setDataGenerate(data);
  };

  useEffect(() => {
    getForm(FORM_CODE_SERVICE.KET_QUA_CLS.code);
  }, []);

  useEffect(() => {
    formikRef.current.setFieldValue(formName, paraclinicalResult);
  }, [paraclinicalResult]);

  const handleFormSubmit = async (values: any) => {
    if (values?.indexItemEdit) {
      let listNguongUpdated = listNguong?.map((item: any, index: any) => {
        if (index === values?.indexItemEdit) {
          return values;
        }
        return item;
      });
      setListNguong(listNguongUpdated);
    } else {
      setListNguong([...listNguong, values]);
    }
  };

  return (
    <>
      <Modal
        className="modal-paraclinical"
        show={true}
        onHide={handleCloseDialog}
        size="lg"
      >
        <Formik<any>
          initialValues={{ ...initialValuesService }}
          onSubmit={handleFormSubmit}
          validationSchema={validationSchema}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            setValues,
            errors,
            touched,
            resetForm,
          }) => {
            formikRef.current = {
              values: values,
              setFieldValue: setFieldValue,
              setValues: setValues,
            };
            const handleChange = (name: string, value: any, type?: string) => {
              if (type === "select" && name === "class") {
                let stringId = name + "Id";
                setFieldValue(formName, {
                  ...values?.[formName],
                  [stringId]: value?.id,
                  [name]: value,
                });
              } else if (type === "select") {
                let stringName = name + "Name";
                let stringCode = name + "Code";
                setFieldValue(formName, {
                  ...values?.[formName],
                  [stringName]: value?.name,
                  [stringCode]: value?.code,
                  [name]: value,
                });
              } else {
                setFieldValue(formName, {
                  ...values?.[formName],
                  [name]: value,
                });
              }
            };
            return (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title>Cập nhật kết quả cận lâm sàn</Modal.Title>
                </Modal.Header>
                <Modal.Body className="spaces p-6">
                  <GenerateFormHTML
                    isView={isView}
                    listFieldAuto={dataGenerate}
                    handleSave={handleSubmit}
                    itemEdit={paraclinicalResult}
                    isSave={false}
                    isUpdate={true}
                    setFieldValue ={setFieldValue}
                    values={values}
                    modelID={formName}
                    errors={errors}
                    touched={touched}
                  />
                </Modal.Body>
                <Modal.Footer className="">
                  <div className="flex bg-white spaces border-top justify-content-end p-0">
                    {!isView && (
                      <Button
                        className="btn-fill spaces min-w-86"
                        onClick={() => handleSubmit()}
                      >
                        <span>Lưu lại</span>
                      </Button>
                    )}
                  </div>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default ParaclinicalResultDialog;
