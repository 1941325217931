import clsx from "clsx";
import { FieldHookConfig, useField, useFormikContext } from "formik";
import React, { FC, forwardRef, useEffect, useRef, useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select, { GetOptionLabel, mergeStyles } from "react-select";
import { toast } from "react-toastify";
import {
  autocompleteObjectStyle,
  multiValueRemove,
} from "../manager/component/StyleObjectAutocomplete";
import { AutoCompletePropsV2, KEY } from "../utils/Constant";

const AutocompleteV2: FC<AutoCompletePropsV2> = forwardRef<
  any,
  AutoCompletePropsV2
>((props, ref) => {
  const {
    options,
    onChange,
    searchFunction,
    isSearchDefauilt = false,
    name,
    autoFocus = false,
    labelClassName,
    labelWidth = 0
  } = props;
  const [field, meta] = useField(name as string | FieldHookConfig<any>);
  const [optionList, setOptionList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [isCheckDataSrcoll, setIsCheckDataSrcoll] = useState<boolean>(false);
  let styles = {
    ...props?.styles,
    ...(props?.isReadOnly ? multiValueRemove : {}),
    control: (styles: any) => ({ ...styles, backgroundColor: "white" }),
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
      const color_bg_selected = "#3C78D8";
      return {
        ...styles,
        color: !isFocused ? "#000000" : "#ffffff",
        margin: "5px",
        width: "calc(100% - 10px)",
        cursor: isDisabled ? "not-allowed" : "default",
        borderRadius: "3px",
        zIndex: isSelected ? 9999 : "auto",
        position: "relative",
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled ? color_bg_selected : undefined,
          color: "#ffffff",
        },
        ":hover": {
          ...styles[":hover"],
          backgroundColor: !isDisabled ? color_bg_selected : undefined,
          color: "#ffffff",
        },
      };
    },
  };
  const combinedStyles = mergeStyles(autocompleteObjectStyle, styles);
  const [keyword, setKeyword] = useState("");

  const convertNameUrl = (value: string, item: any) => {
    const array = value.split(".");
    for (let i = 0; i < array.length; i++) {
      item = item?.[array[i]];
    }
    return item;
  };

  const fetchData = async () => {
    if (options?.length > 0 && !props.searchFunction) {
      setOptionList(options);
    } else if (!isLoading) {
      setIsLoading(true);
      try {
        if (props.searchObject !== undefined && props.searchFunction) {
          let data = await getData(props.searchObject);
          setPageIndex(props.searchObject?.pageIndex || 1);
          if(props?.isMulti) {
            setOptionList(data?.map((item: any) => {
              return {
                ...item,
                value: item?.id,
                label: item?.name
              }
            }) || []);
          } 
          else {
            setOptionList(data || []);
          }
          setIsCheckDataSrcoll(data?.length > 0);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (props.value && !optionList?.length) {
      fetchData();
    } else {
      ((isFocus && optionList?.length === 0) ||
        (props?.isSrcoll && !keyword)) &&
        fetchData();
    }
    return () => {
      setIsLoading(false);
    };
  }, [options, searchFunction, isFocus, props.value]);

  useEffect(() => {
    isSearchDefauilt && fetchData();
  }, [isSearchDefauilt]);

  const getData = async (searchObject: any) => {
    const res = await props.searchFunction?.(searchObject);
    let data = props?.urlData
      ? convertNameUrl(props?.urlData, res)
      : res?.data?.data?.content || res?.data?.data;
    return data;
  };

  useEffect(() => {
    setOptionList([]);
    setSelectedValue(null);
  }, props?.dependencies || []);

  useEffect(() => {
    getValue();
  }, [props.value, optionList]);

  const handleChange = (selectedOption: any) => {
    setSelectedValue(selectedOption);
    onChange?.(selectedOption);
  };
  const combinedClassName = clsx(
    props?.className ? props.className : "w-100",
    clsx(
      props.className,
      props.errors && props.touched && "ac-is-invalid is-invalid",
      "autocomplete-custom"
    )
  );

  const handleFilterByCode = (code: any) => {
    let obj = optionList?.filter((i) => i.code == code || i?.id == code);
    return obj;
  };

  const getValue = () => {
    if (props?.value) {
      if (typeof props?.value == "string" || typeof props?.value == "number") {
        setSelectedValue(handleFilterByCode(props?.value));
      } else {
        if (typeof props?.value == "object") {
          setSelectedValue(props?.value);
        }
      }
    } else {
      setSelectedValue(null);
    }
  };

  const handleScrollToBottom = async () => {
    try {
      if (isCheckDataSrcoll) {
        let searchObject = { ...props.searchObject };
        searchObject.pageIndex = pageIndex + 1;
        if (props?.labelSearch) searchObject[props?.labelSearch] = keyword;

        setIsLoading(true);
        let data = await getData(searchObject);
        setPageIndex(pageIndex + 1);
        setIsCheckDataSrcoll(data?.length > 0);
        setOptionList([...optionList, ...data]);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Xảy ra lỗi, vui lòng thử lại!");
    }
  };

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLElement>) => {
    if (KEY.SPACE === event.code && !(event.target as HTMLInputElement).value) {
      event.preventDefault();
      return;
    }
  };

  const handleInputChange = (newValue: string) => {
    setKeyword(newValue?.trim());
  };

  const handeBlur = () => {
    setIsFocus(false);
    setKeyword("");
    props?.onBlur?.();
  };

  useEffect(() => {
    searchOption();
  }, [keyword, selectedValue]);

  const searchOption = async () => {
    try {
      if (props?.isSrcoll && !selectedValue) {
        let searchObject = { ...props.searchObject };
        if (props?.labelSearch) searchObject[props?.labelSearch] = keyword;
        setIsLoading(true);
        let data = await getData(searchObject);
        setPageIndex(props.searchObject?.pageIndex || 1);
        setOptionList(data);
        setIsCheckDataSrcoll(data?.length > 0);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Xảy ra lỗi, vui lòng thử lại!");
    }
  };
  const renderTooltip = (props: any) =>
    (meta.error && meta.touched) || (props?.errors && props?.touched) ? (
      <Tooltip id="button-tooltip">
        <div className="text-danger">
          {meta.error ? meta.error : props?.errors}
        </div>
      </Tooltip>
    ) : (
      <div></div>
    );

  const inputRef = useRef<any>(null);
  const { submitCount, isSubmitting } = useFormikContext();

  useEffect(() => {
    if (submitCount > 0 || isSubmitting) {
      const invalidElements = document.querySelectorAll(".is-invalid");

      if (invalidElements.length > 0) {
        const firstInvalidElement = invalidElements[0].querySelector('input') as HTMLElement;
        firstInvalidElement?.focus();
        firstInvalidElement?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [submitCount, isSubmitting]);

  return (
    <div className={`text-field-v2 ${props?.className} ${props?.labelPosition === 'left-left' ? "d-flex" : ""}`}>
      {props?.label && (
        <span
          className={`text-lable-input max-content-width ${
            labelClassName ? labelClassName : "me-2"
          }`}
        >
          {props?.label}
          {props?.isRequired && <span className="text-danger"> (*)</span>}
        </span>
      )}
      <Form.Group className={`position-relative spaces width-${100 - Number(labelWidth)}`}>
      <Select
        ref={ref || inputRef}
        autoFocus={props?.autoFocus}
        getOptionLabel={(option: GetOptionLabel<any>) =>
          props.getOptionLabel ? props.getOptionLabel(option) : option.name
        }
        getOptionValue={(option: any) =>
          props.getOptionValue ? props.getOptionValue(option) : option?.value
        }
        backspaceRemovesValue={props?.backspaceRemovesValue}
        options={optionList}
        noOptionsMessage={({ inputValue } : { inputValue: string }) =>
          `Không tìm thấy lựa chọn cho "${inputValue}"`
        }
        className={combinedClassName}
        name={props?.name}
        value={selectedValue}
        id={props?.id}
        key={props?.key}
        onFocus={() => setIsFocus(true)}
        onBlur={handeBlur}
        isDisabled={props?.isDisabled}
        isLoading={isLoading}
        styles={combinedStyles}
        minMenuHeight={props?.minMenuHeight}
        maxMenuHeight={props?.maxMenuHeight}
        placeholder={
          <p className="custom-placeholder spaces m-0">
            {props?.placeholder || "Chọn..."}
          </p>
        }
        onChange={handleChange}
        // menuPortalTarget={document.getElementById("root")}
        // menuPortalTarget={props?.menuPortalTarget}
        isMulti={props?.isMulti}
        closeMenuOnSelect={props?.closeMenuOnSelect}
        menuPlacement={props?.menuPlacement ? props?.menuPlacement : "auto"}
        onMenuScrollToBottom={
          props?.isSrcoll ? handleScrollToBottom : undefined
        }
        onKeyDown={handleKeyDown}
        onInputChange={handleInputChange}
        hideSelectedOptions={props?.isReadOnly}
        menuIsOpen={props?.isReadOnly ? false : undefined}
        isSearchable={
          props?.isReadOnly
            ? false
            : props?.isSearchable !== undefined
            ? props?.isSearchable
            : true
        }
        isClearable={
          props?.isReadOnly
            ? false
            : props?.isClearable !== undefined
            ? props?.isClearable
            : true
        }
      />
      <Form.Control.Feedback
        type="invalid"
        tooltip
        className="field-tooltip-error"
      >
        {props.errors || meta.error}
      </Form.Control.Feedback>
    </Form.Group>
    </div>
  );
});
export default AutocompleteV2;
