import axios, { AxiosResponse } from "axios";
import { APIResponse } from "../../models/models";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
import { paramsConfig } from "../../utils/ParamsUtils";

const EMR_API_URL = (localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["emrUrl"] || process.env.REACT_APP_XEMR_ENDPOINT);
const API_PATH_TERM_CLASSES = EMR_API_URL + "/term-classes";

export const getDSDichVuKyThuat = (data: any) => {
  let url = EMR_API_URL + "/terms/clinical-package";
  return axios.get(url, paramsConfig(data));
};

export const getListPackage = (data: any) => {
  let url = EMR_API_URL + "/package/search";
  return axios.get(url, paramsConfig(data));
};

export const getPackageById = (termid?: any) => {
  let url = `${EMR_API_URL}/terms/get-clinical-package/${termid}`;
  return axios.get(url);
};

export const updatePackage = (id: string, packageData: any): Promise<AxiosResponse<APIResponse>> => {
  let url = `${EMR_API_URL}/package/${id}`;
  return axios.put(url, packageData);
};

export const addNewPackage = (packageData: any) => {
  let url = `${EMR_API_URL}/package`;
  return axios.post(url, packageData);
};

export const deletePackage = (termid: string): Promise<AxiosResponse<APIResponse>> => {
  let url = `${EMR_API_URL}/package/${termid}`;
  return axios.delete(url);
};

export const exportToExcelPackageList = (searchObject: any) => {
  return axios({
    method: "post",
    url:
    EMR_API_URL +
      "/terms/clinical-packages/export",
    data: searchObject,
    responseType: "blob",
  });
};

export const getTermClasses = (params: any) => {
  let url = API_PATH_TERM_CLASSES + "/terms";
  return axios.get(url, { params });
};

export const addServicesToPackage = (id: any, packageData: any) => {
  let url = `${EMR_API_URL}/package/${id}/items`;
  return axios.put(url, packageData);
};

export const getServicesInPackage = (id: any) => {
  let url = `${EMR_API_URL}/package/${id}/items`;
  return axios.get(url);
};
