import {
  columnNamesType,
  OctTable,
  useMultiLanguage,
} from "@oceantech/oceantech-ui";
import React, { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import CustomTooltip from "../../../../component/CustomTooltip";
import AppContext from "../../../../../AppContext";
import ParaclinicalResultDialog from "../ParaclinicalResultDialog";

type Props = {
  formName: string;
  listNguong: any;
  setListNguong: (listItem: any) => void;
};

const FormParaclinicalResults = ({
  formName,
  listNguong,
  setListNguong,
}: Props) => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const [paraclinicalResult, setParaclinicalResult] = useState<any>(null);
  const [isViewPractitation, setIsViewPractitation] = useState<boolean>(false);

  const handleDeleteParaclinicalResult = (indexItemDelete: any) => {
    let listNguongDeleted = listNguong?.filter(
      (item: any, index: any) => indexItemDelete !== index
    );
    setListNguong(listNguongDeleted);
  };

  const columns: columnNamesType[] = [
    {
      name: "Khoảng",
      field: "code",
      headerStyle: {
        minWidth: "90px",
      },
      cellStyle: {
        textAlign: "center",
      },
      // render: (row) => <span>{row?.username}</span>,
    },
    {
      name: "Tuổi",
      field: "age",
      headerStyle: {
        minWidth: "90px",
      },
      cellStyle: {
        textAlign: "center",
      },
      // render: (row) => <span>{row?.username}</span>,
    },
    {
      name: "Giới tính",
      field: "gender",
      headerStyle: {
        minWidth: "100px",
      },
      cellStyle: {
        textAlign: "center",
      },
      // render: (row) => <span>{row?.roles?.toString()}</span>,
    },
    {
      name: "Mô tả",
      field: "unit",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "left",
      },
      // render: (row) => <span>{row?.email}</span>,
    },
    {
      name: "Bất thường",
      field: "low",
      headerStyle: {
        minWidth: "100px",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      name: "Thấp",
      field: "svcFeeHospital",
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      name: "Cao",
      field: "svcFee",
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      name: "Quá thấp",
      field: "low",
      headerStyle: {
        minWidth: "80px",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      name: "Quá cao",
      field: "high",
      headerStyle: {
        minWidth: "80px",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      name: "In",
      field: "print",
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      name: "In thay kết quả",
      field: "print",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      name: "Ghi chú",
      field: "note",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
  ];

  const actionsColumn: any[] = [
    {
      name: lang("TABLE.ACTION"),
      field: "action",
      isVisible: true,
      headerStyle: {
        minWidth: "100px",
      },
      render: (rowData: any, index: any) => (
        <div>
          <ButtonGroup size="sm">
            <CustomTooltip title={lang("BTN.DELETE")}>
              <Button
                onClick={() => handleDeleteParaclinicalResult(index)}
                variant="outline-danger"
                className="hover-white"
              >
                <i className="bi bi-trash-fill px-0" color="inherit"></i>
              </Button>
            </CustomTooltip>
            <CustomTooltip title={lang("BTN.EDIT")}>
              <Button
                onClick={() => {
                  setParaclinicalResult({ ...rowData, indexItemEdit: index });
                }}
                variant="outline-warning"
                className="hover-white"
              >
                <i className="bi bi-pencil-fill px-0"></i>
              </Button>
            </CustomTooltip>
            <CustomTooltip title={lang("BTN.VIEW")}>
              <Button
                onClick={() => {
                  setParaclinicalResult({ ...rowData });
                  setIsViewPractitation(true);
                }}
                variant="outline-primary"
                className="hover-white"
              >
                <i className="bi bi-eye-fill px-0" color="inherit"></i>
              </Button>
            </CustomTooltip>
          </ButtonGroup>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-end">
        <Button className="" onClick={() => setParaclinicalResult({})}>
          Thêm khoảng
        </Button>
      </div>
      <OctTable
        data={listNguong}
        columns={columns}
        scrollable
        actionsColumn={actionsColumn}
        className="custom-list-data"
        noPagination
        notDelete
        noToolbar
      />

      {paraclinicalResult && (
        <ParaclinicalResultDialog
          isView={isViewPractitation}
          handleCloseDialog={() => {
            setParaclinicalResult(null);
            setIsViewPractitation(false);
          }}
          paraclinicalResult={paraclinicalResult}
          formName={formName}
          listNguong={listNguong}
          setListNguong={setListNguong}
        />
      )}
    </div>
  );
};

export default FormParaclinicalResults;
