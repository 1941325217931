import { Button, Col, Image, Modal, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import GenerateFormHTML from "../../../../component/generate-form/GenerateFormHTML";
import { useFormikContext } from "formik";
import { FORM_CODE_SERVICE } from "../../../../constant";
import FormParaclinicalResults from "./FormParaclinicalResults";

type Props = {
  isView: boolean;
  handleSave: (values: any) => void;
  listFieldAuto: any;
  itemEdit: any;
  formName: string;
  errors?: any;
  touched?: any;
};

export default function FormService({
  handleSave,
  isView,
  listFieldAuto,
  itemEdit,
  formName,
  errors,
  touched,
}: Props) {
  const { values, setFieldValue } = useFormikContext<any>();

  return (
    <GenerateFormHTML
      isView={isView}
      listFieldAuto={listFieldAuto}
      handleSave={handleSave}
      itemEdit={itemEdit}
      isSave={false}
      isUpdate={true}
      setFieldValue={setFieldValue}
      values={values}
      modelID={formName}
      errors={errors}
      touched={touched}
    />
  );
}
