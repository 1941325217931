import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import AppContext from "../../AppContext";
import { localStorageItem } from "../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../auth/core/_consts";
import axios from "axios";
import { RESPONSE_STATUS_CODE } from "../utils/Constant";
import { toast } from "react-toastify";

interface IProps {
  url: string;
  handleOKEditClose?: () => void;
  className?: string;
  buttonName?: string;
}

function ButtonImportExcel(props: IProps) {
  const { handleOKEditClose = () => {}, url, className = "" } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<any>(null);
  const [fileError, setFileError] = useState<any>(null);
  const { setPageLoading } = useContext(AppContext);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (!selectedFiles) return;

    const fileList = Array.from(selectedFiles).map((file) => ({
      file,
      uploading: false,
      error: false,
      progress: 0,
    }));

    setFiles(fileList);
    if (fileList && fileList.length > 0) {
      uploadSingleFile(fileList);
    }
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const fileUpload = (file: File) => {
    const defaultUrl =
      (localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["emrUrl"] ||
        process.env.REACT_APP_XADMIN_ENDPOINT ||
        process.env.REACT_APP_EMR_ENDPOINT) + url;

    const formData = new FormData();
    formData.append("filePath", file);

    return axios.post(defaultUrl, formData, { timeout: 60000,
      headers: { "Content-Type": "multipart/form-data" },
    });
  };

  const uploadSingleFile = (fileList: any) => {
    let index = fileList.length - 1;
    setPageLoading(true);
    let allFiles = [...fileList];
    const file = fileList[index];
    fileUpload(file.file)
      .then((res) => {
        setPageLoading(false);
        if (RESPONSE_STATUS_CODE.SUCCESS === res?.data?.code) {
          toast.success("Import thành công");
          handleOKEditClose();
        } else {
          setFileError({
            urlFileError:
              res?.data?.data?.[0]?.urlGetFile ||
              res?.data?.apiSubErrors?.[0]?.urlGetFile,
            messageError: res?.data?.message,
            error: true,
          });
        }
      })
      .catch(() => {
        toast.error("Xảy ra lỗi, vui lòng thử lại!");
        setPageLoading(false);
      });

    allFiles[index] = { ...file, uploading: true, error: false };
    setFiles([...allFiles]);
  };

  const openFileDialog = () => {
    inputRef.current?.click();
  };

  return (
    <div className={`${className} spaces mx-5`}>
      <Button className="spaces h-32" size="sm" onClick={openFileDialog}>
        <div className="flex flex-middle">
          <i className="bi bi-upload"></i>
          <p className="spaces fs-14 m-0 w-max-content">{props?.buttonName || "Import Excel"}</p>
        </div>
      </Button>
      <input
        ref={inputRef}
        style={{ display: "none" }}
        onChange={handleFileSelect}
        id="upload-single-file"
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
    </div>
  );
}

export default ButtonImportExcel;
