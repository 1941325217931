import { useNavigate } from 'react-router-dom'
import './homepage.scss'
import { useAuth } from '../../modules/auth';
import { localStorageItem } from '../../modules/utils/LocalStorage';
import { KEY_LOCALSTORAGE } from '../../modules/auth/core/_consts';

export function HomePage() {
  const navigate = useNavigate();
  const mainMenus = localStorageItem.get(KEY_LOCALSTORAGE.ID_TOKEN_DECODE).mainmenus;
  const mainMenuConvert = () => {
    let combinedMenuList:any = [];
    Object.values(mainMenus || []).forEach((mainMenu : any) => {
      const updatedMenu = mainMenu.menu.map((item : any) => ({
        ...item,
        uri: process.env.REACT_APP_SSO_CLIENT_ID !== mainMenu.serviceCode && mainMenu.serviceWebClient? mainMenu.serviceWebClient + item.uri : item.uri
      }));
      combinedMenuList = combinedMenuList.concat(updatedMenu);
    });
    combinedMenuList.sort((a:any, b:any) => a.order - b.order);

    return combinedMenuList;
  };
  const isGroupByServiceCode = false;
  const { logout} = useAuth();

  const handleButtonClick = (to: string) => {
    // Check if `to` is a URL or a path
    if (to.startsWith("http://") || to.startsWith("https://")) {
        // Open the URL in a new tab
        window.open(to, '_blank');
    } else {
        // Use navigate for internal paths
        navigate(to);
    }
};

  return (
    <div className="main">
      <div className="container">
          <div>
            <div className='d-flex justify-content-center spaces mt-10'>
              <img src="/media/logos/xHIS.png" alt="Logo xHis" className='spaces mt-15'/>
            </div>
            <p className="text-uppercase text-left text-white fw-normal heading-3 my-20 spaces fs-22">
              PHẦN MỀM QUẢN LÝ PHÒNG KHÁM
            </p>
          </div>
          {isGroupByServiceCode && mainMenus.map((service: any, index: any) => (
              <>
                  <div key={index}>
                    <p className="text-uppercase text-left text-white fw-normal heading-3 mb-5">
                        {service.serviceName}
                    </p>
                  </div> 
                  <div key={index+"-btn-group"} className="button-group">
                    {service.menu.map((item: any, itemIndex: any) => (
                      <div className="item-button" key={itemIndex}>
                        <button
                          className="btn btn-light-primary fw-bolder btn-homepage"
                          onClick={() => handleButtonClick(process.env.REACT_APP_SSO_CLIENT_ID !== item.serviceCode && item.serviceWebClient? item.serviceWebClient + item?.uri:item?.uri)}
                        >
                          <img src={item?.icon?`data:image/svg+xml;base64,${item?.icon}`:"media/svg/dashboard/bills.svg"} alt={item?.name}></img>
                        </button>
                        <span className="title-button">{item?.name}</span>
                      </div>
                    ))}
                  </div>
              </>
          ))}
          {!isGroupByServiceCode && 
            <>
              <div className="button-group">
                {mainMenuConvert().map((item: any, index: any) => (
                    <div className="item-button" key={index}>
                      <button
                        className="btn btn-light-primary fw-bolder btn-homepage"
                        onClick={() => handleButtonClick(item?.uri)}
                      >
                        <img src={item?.icon?`data:image/svg+xml;base64,${item?.icon}`:"media/svg/dashboard/bills.svg"} alt={item?.name}></img>
                      </button>
                      <span className="title-button">{item?.name}</span>
                    </div>
                ))}
              </div>
            </>
          }
      </div>
      <div className='icon-button'>
        <i className="bi bi-box-arrow-left cursor-pointer icon" onClick={logout}></i>
      </div>
    </div>
  );
}