import { ChangeEvent, FC } from 'react';
import { Form, FormControl } from 'react-bootstrap';

type Props = {
  value?: string
  name?: string
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  handleSearch?: () => void;
  placeholder?: string;
  type?: string;
  className?: string;
  size?: "sm" | "lg";
  onKeyPress?: (event: React.KeyboardEvent) => void;
}

const InputSearch: FC<Props> = ({ ...props }) => {
    let {
      value,
      name,
      handleChange,
      handleKeyDown,
      handleSearch,
      type,
      className,
      placeholder,
      size,
      onKeyPress,
    } = props;
    return (
      <Form.Group className="d-flex position-relative">
        <FormControl
          className={`form-control customs-input ${className ? className : ""}`}
          value={value}
          name={name}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder ? placeholder : ""}
          type={type ? type : "text"}
          size={size}
          onKeyUp={onKeyPress}
        />

        <div className="searchTextField" onClick={handleSearch}>
          <i className="bi bi-search"></i>
        </div>
      </Form.Group>
    );
};

export default InputSearch