import {
  columnNamesType,
  OctTable,
  useMultiLanguage,
} from "@oceantech/oceantech-ui";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../../../AppContext";
import {
  getDSChiSoCon,
} from "../../../services/ManagerServices";
import { IObject } from "../../../../models/models";
import { FORM_CODE_SERVICE, INIT_SEARCH_OBJECT } from "../../../../constant";
import { RESPONSE_STATUS_CODE, TYPE } from "../../../../utils/Constant";
import { useFormikContext } from "formik";

type Props = {
  formName: string;
  selectedIndicator: any;
  setSelectedIndicator: (listItem: any) => void;
  currentTab?: any;
};

const FormParaclinicalIndicator = ({
  selectedIndicator,
  setSelectedIndicator,
  currentTab,
}: Props) => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);
  const { values, setFieldValue } = useFormikContext<any>();

  const [listIndicator, setListIndicator] = useState<any>([]);
  const [searchObject, setSearchObject] = useState<IObject>(INIT_SEARCH_OBJECT);

  const handleGetListIndicator = async () => {
    try {
      setPageLoading(true);
      const { data } = await getDSChiSoCon(searchObject);
      if (RESPONSE_STATUS_CODE.SUCCESS === data.code) {
        setListIndicator(data?.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    handleGetListIndicator();
  }, []);

  useEffect(() => {
    if (
      values?.[FORM_CODE_SERVICE.DICH_VU_KY_THUAT?.code]?.id &&
      listIndicator?.length > 0 &&
      currentTab === FORM_CODE_SERVICE.CHI_SO_CLS?.tabIndex
    ) {
      let selectedIndicator = listIndicator.filter(
        (indicator: any) =>
          indicator?.parentId ===
          values?.[FORM_CODE_SERVICE.DICH_VU_KY_THUAT?.code]?.id
      );
      setSelectedIndicator(selectedIndicator);
    }
  }, [currentTab, values?.[FORM_CODE_SERVICE.DICH_VU_KY_THUAT?.code]?.id, listIndicator]);

  const columns: columnNamesType[] = [
    {
      name: "Mã chỉ số",
      field: "code",
      headerStyle: {
        maxWidth: "100px",
      },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      name: "Tên chỉ số",
      field: "name",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "left",
      },
    },
  ];

  return (
    <div>
      <OctTable
        data={listIndicator}
        columns={columns}
        isActionTableTab
        scrollable
        notDelete
        className="custom-list-data table-indicator"
        noToolbar
        noPagination
        type={TYPE.MULTILINE}
        setDataChecked={setSelectedIndicator}
        dataChecked={selectedIndicator}
      />
    </div>
  );
};

export default FormParaclinicalIndicator;
